import { TypedMcapRecords } from '@mcap/core/dist/esm/src/types'

const protobuf = require('protobufjs')
const descriptor = require('protobufjs/ext/descriptor/index.js')

export interface Timestamp {
  seconds: number
  nanos: number
}

enum NavSatStatus {
  STATUS_FIX = 0,
  STATUS_SBAS_FIX = 1,
  STATUS_GBAS_FIX = 2,
  STATUS_NO_FIX = -1,
}

enum NavService {
  SERVICE_NONE = 0,
  SERVICE_GPS = 1,
  SERVICE_GLOSNASS = 2,
  SERVICE_COMPASS = 4,
  SERVICE_GALILEO = 8,
}

enum NavSatFix {
  COVARIANCE_TYPE_UNKNOWN = 0,
  COVARIANCE_TYPE_APPROXIMATED = 1,
  COVARIANCE_TYPE_DIAGONAL_KNOWN = 2,
  COVARIANCE_TYPE_KNOWN = 3,
}

enum Gear {
  P = 0,
  R = 1,
  N = 2,
  D = 3,
  B = 4,
}

export enum TurnSignal {
  OFF = 0,
  LEFT = 1,
  RIGHT = 2,
}

interface DriveSessionInfo {
  drive_name: string
  time_stamp: Timestamp
  version: number
}

export interface Gnss {
  altitude: number
  has_highprecision_loc: boolean
  heading: number
  heading_error: number
  hp_loc_altitude: number
  hp_loc_latitude: number
  hp_loc_longitude: number
  latitude: number
  longitude: number
  position_covariance: number[]
  position_covariance_type: NavSatFix
  service: NavService
  session_timestamp: number
  speed: number
  speed_error: number
  status: NavSatStatus
  time_stamp: Timestamp
}

export interface VehicleMotion {
  time_stamp: Timestamp
  session_timestamp: number
  acceleration_x: number
  acceleration_y: number
  acceleration_z: number
  speed: number
  wheel_speed_fr: number
  wheel_speed_fl: number
  wheel_speed_rr: number
  wheel_speed_rl: number
  steering_angle: number
  steering_angle_normalized: number
  gas_pedal_normalized: number
  brake_pedal_normalized: number
  instructor_pedals_used: boolean
  gear: Gear
  speed_dashboard: number
  mileage: number
}

export interface VehicleState {
  time_stamp: Timestamp
  session_timestamp: number
  door_closed_fl: boolean
  door_closed_fr: boolean
  door_closed_rl: boolean
  door_closed_rr: boolean
  driver_seatbelt_on: boolean
  passenger_seatbelt_on: boolean
  headlight_high_beam_on: boolean
  turn_signal: TurnSignal
}

export interface CurriculumPoint {
  location: Location
  timestamp: Timestamp
  type: number
}

export interface CurriculumLineString {
  end: Timestamp
  locations: Location[]
  start: Timestamp
  type: number
}

export interface Metadata {
  driveSessionInfo: DriveSessionInfo[]
  gnss: Gnss[]
  vehicleMotion: VehicleMotion[]
  vehicleState: VehicleState[]
  safetyScore: SafetyScore[]
  way: Way[]
  curriculumPoint: CurriculumPoint[]
  curriculumLineString: CurriculumLineString[]
}

export interface Location {
  altitude: number
  latitude: number
  longitude: number
  timestamp: Timestamp
}

export interface Clip {
  end_timestamp: Timestamp
  start_timestamp: Timestamp
  locations: Location[]
}

export interface Prediction {
  action: number
  action_groundtruth_logprob: number
  action_l1_norm_prediction_and_groundtruth: number
  action_prediction: number
}

export interface SafetyScore {
  clip: Clip
  evaluation: number
  predictions: Prediction[]
  score: number
}

export interface Way {
  end: {
    seconds: number
  }
  highway: number
  length: number
  locations: Location[]
  maxspeed?: number
  start: Timestamp
  surface: number
  lanes?: number
}

export const calculateAcceleration = (vehicleMotion: VehicleMotion) =>
  Math.sqrt(
    Math.pow(vehicleMotion.acceleration_x || 0, 2) +
      Math.pow(vehicleMotion.acceleration_y || 0, 2) +
      Math.pow(vehicleMotion.acceleration_z || 0, 2)
  )

export const readBinaryFile = async (blob: Blob) => {
  const buffer = await blob.arrayBuffer()
  const bytes = new Uint8Array(buffer)

  const view = new DataView(buffer)

  const messageHeaderLength = view.getUint32(8, true)

  const root = protobuf.Root.fromJSON(require('./metadata.json'))

  const result: Metadata = {
    driveSessionInfo: [],
    gnss: [],
    vehicleMotion: [],
    vehicleState: [],
    safetyScore: [],
    way: [],
    curriculumPoint: [],
    curriculumLineString: [],
  }

  let index = 12
  let length = bytes.length - index
  while (length >= messageHeaderLength) {
    const firstIndex = index + 4
    const messageIndex = index + 4 * 2
    const msgType = view.getUint32(index, true)
    const msgLength = view.getUint32(firstIndex, true)
    const message = bytes.slice(messageIndex, messageIndex + msgLength)
    index = messageIndex + msgLength
    length = bytes.length - index
    switch (msgType) {
      case 0: {
        const gnss = root.lookupType('intercom.Gnss')
        result.gnss.push(gnss.decode(message))
        break
      }
      case 6: {
        const driveSessionInfo = root.lookupType('intercom.DriveSessionInfo')
        result.driveSessionInfo.push(driveSessionInfo.decode(message))
        break
      }
      case 7: {
        const vehicleMotion = root.lookupType('intercom.VehicleMotion')
        result.vehicleMotion.push(vehicleMotion.decode(message))
        break
      }
      case 8: {
        const vehicleState = root.lookupType('intercom.VehicleState')
        result.vehicleState.push(vehicleState.decode(message))
        break
      }
      default:
        break
    }
  }

  return result
}

export const parseProtobufSchema = (
  schemaName: string,
  schemaData: Uint8Array
) => {
  const descriptoSet = descriptor.FileDescriptorSet.decode(schemaData)
  const root = protobuf.Root.fromDescriptor(descriptoSet)
  root.resolveAll()

  return root.lookupType(schemaName)
}

export const parseMessages = async (
  messageIterator: AsyncGenerator<TypedMcapRecords['Message'], void, void>,
  channelIdToProtoSchema: Map<number, { protoSchema: any; schemaName: string }>
): Promise<Metadata> => {
  const result: Metadata = {
    driveSessionInfo: [],
    gnss: [],
    vehicleMotion: [],
    vehicleState: [],
    safetyScore: [],
    way: [],
    curriculumPoint: [],
    curriculumLineString: [],
  }

  for await (const message of messageIterator) {
    const info = channelIdToProtoSchema.get(message.channelId)

    switch (info?.schemaName) {
      case 'intercom.Gnss': {
        result.gnss.push(info?.protoSchema.decode(message.data) as Gnss)
        break
      }
      case 'intercom.DriveSessionInfo': {
        result.driveSessionInfo.push(
          info?.protoSchema.decode(message.data) as DriveSessionInfo
        )
        break
      }
      case 'intercom.VehicleMotion': {
        result.vehicleMotion.push(
          info?.protoSchema.decode(message.data) as VehicleMotion
        )
        break
      }
      case 'intercom.VehicleState': {
        result.vehicleState.push(
          info?.protoSchema.decode(message.data) as VehicleState
        )
        break
      }
      case 'osm.SafetyScore': {
        result.safetyScore.push(
          info?.protoSchema.decode(message.data) as SafetyScore
        )
        break
      }
      case 'osm.Way': {
        result.way.push(info?.protoSchema.decode(message.data) as Way)
        break
      }
      case 'osm.CurriculumPoint': {
        result.curriculumPoint.push(info?.protoSchema.decode(message.data))
        break
      }
      case 'osm.CurriculumLineString': {
        result.curriculumLineString.push(info?.protoSchema.decode(message.data))
        break
      }
    }
  }

  return result
}
